import React, { useReducer, useEffect } from 'react'
import AxiosInstance from '../../utils/AxiosInstance'

import PartStockContext from './partStockContext';
import PartStockReducer from './partStockReducer';
import {
    PART_STOCK_GET,
    PART_STOCK_ADD,
    PART_STOCK_COUNT,
    LOADING_SET,
    ERROR
} from '../types';

const PartStockState = props => {
    const initialState = {
        partId: null,
        current_amount: 0,
        history: [],
        update: false,
        loading: false
    };

    const [state, dispatch] = useReducer(PartStockReducer, initialState);

    useEffect(() => {
        if (state.update && state.partId !== null) {
            setLoading();
            getStock(state.partId)
        }
        // eslint-disable-next-line
    }, [state.update])

    // Set Loading
    const setLoading = () => dispatch({ type: LOADING_SET });

    // Get Part
    const getStock = async partId => {

        // update stock count
        getCount(partId)

        try {
            const res = await AxiosInstance.get('/part-stock?part_id=' + partId);
            dispatch({
                type: PART_STOCK_GET,
                partId: partId,
                payload: res.data
            });
        } catch (err) {

            dispatch({
                type: ERROR,
                payload: (err.response.data.message && err.response.data.message)
            });
        }
    };

    // count stock
    const getCount = async partId => {
        try {
            const res = await AxiosInstance.get('/part-stock-count/' + partId);
            dispatch({
                type: PART_STOCK_COUNT,
                payload: res.data
            });
        } catch (err) {
            dispatch({
                type: ERROR,
                payload: (err.response.data.message && err.response.data.message)
            });
        }
    }

    // Add Stock
    const addStock = async (data) => {
        setLoading();

        try {
            const res = await AxiosInstance.post('/part-stock', data);
            dispatch({
                type: PART_STOCK_ADD,
                payload: res.data
            });
        } catch (err) {
            dispatch({
                type: ERROR,
                payload: (err.response.message && err.response.message)
            });
        }
    };

    return <PartStockContext.Provider
        value={{
            loading: state.loading,
            error: state.error,
            history: state.history,
            current_amount: state.current_amount,
            getStock,
            addStock
        }}
    >
        {props.children}
    </PartStockContext.Provider>

}

export default PartStockState;