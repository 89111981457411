import React, { Fragment } from 'react'
import { Switch, Route } from 'react-router-dom'

import DrawingForm from '../drawing/DrawingForm'
import DrawingDetail from '../drawing/DrawingDetail'

const Main = () => {
  return (
    <Fragment>
      <Switch>
        <Route path="/view/:id" component={DrawingDetail} />
        <Route path="/drawing/:id" component={DrawingForm} />
        <Route path="/drawing" component={DrawingForm} />
      </Switch>
    </Fragment>
  )
}

export default Main
