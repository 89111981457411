import React from 'react';
import Alerts from  './Alerts'

const Header = (props) => {

  return (
    <header>
      <div className="logo">ePit Katalog</div>
      <nav className="menu">
      </nav>
      <Alerts />
    </header>
  );
};
export default Header;
