import React, { useEffect, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import PartStockContext from '../../context/partStock/partStockContext'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/free-solid-svg-icons';

const PartStock = (prop) => {

  const context = useContext(PartStockContext)

  const initialState = {
    part_id: null,
    user_name: 'default',
    comment: '',
    amount: 0
  }

  const [form, setForm] = useState(initialState);

  useEffect(() => {
    // store part id
    setForm({ ...form, part_id: prop.partId })
    context.getStock(prop.partId)
    // eslint-disable-next-line
  }, [prop.partId])

  const onChange = e => {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  const onSubmit = e => {
    e.preventDefault();
    context.addStock(form)
    // reset form
    setForm({ ...form, comment: '', amount: 0 })
  };

  return (
    <div className="part-stock-container">

      <h3>Aktuální stav skladu: {context.current_amount}</h3>
      <form onSubmit={onSubmit}>
        <div className="group">
          <div>
            <h4>Komentář</h4>
            <input type="text" placeholder="komentář" name="comment" value={form.comment || ''} onChange={onChange} />
          </div>
          <div>
            <h4>Počet</h4>
            <input type="text" placeholder="počet" name="amount" value={form.amount || ''} onChange={onChange} />
          </div>
          <button className="icon_button" onClick={onSubmit}><FontAwesomeIcon icon={faSave} size="2x" title="Uložit" /></button>
        </div>
      </form>

      <table className="part-stock-history">
        <thead>
          <tr>
            <th>Datum</th>
            <th>Uživatel</th>
            <th>Komentář</th>
            <th>Počet</th>
          </tr>
        </thead>
        <tbody>
          {context.history && context.history.map((item) =>
            <tr key={item.id}>
              <td>{new Date(item.created_at).toLocaleDateString('cs-CS')}</td>
              <td>{item.user_name}</td>
              <td>{item.comment}</td>
              <td>{item.amount}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div >
  )
}

PartStock.propTypes = {
  partId: PropTypes.number.isRequired,
}

export default PartStock
