import React from 'react';

const Footer = () => {

  return (
    <footer>
      <div className="left">
      </div>
      <div className="right">
        <span className="copyright">&copy;2019 Palkoautomotive</span>
      </div>
    </footer>
  )
}
export default Footer;
