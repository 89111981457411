import React, { useEffect, useContext, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faEdit } from '@fortawesome/free-solid-svg-icons';
import { MapInteractionCSS } from 'react-map-interaction';


import DrawingContext from '../../context/drawing/drawingContext'
import Spinner from '../layout/Spinner'
import Modal from '../modal/Modal'
import DrawingItemList from './DrawingItemList';

const DrawingDetail = ({ match, history, boundingClientRect = {} }) => {
    const drawingContext = useContext(DrawingContext)

    const [element, setElement] = useState(null);
    const [parts, setParts] = useState([]);
    const [loadingParts, setLoadingPart] = useState(false)
    const [viewBox, setViewBox] = useState(null)
    const [showItemList, setShowItemList] = useState(false)

    useEffect(() => {
        // change drawing based on route
        if (match.params.id && match.params.id > 0) {
            drawingContext.getDrawing(match.params.id)
            setViewBox(null)
        }
        // eslint-disable-next-line
    }, [match])
    useEffect(() => {
        // update element
        setElement(document.getElementById('drawing'))
        // load parts and calculate viewBox
        if (viewBox == null && element !== null && drawingContext.current !== null) {
            setLoadingPart(true)
            // find all parts in svg
            let pel = findParts(element)
            setParts(pel)
            // load original vbox
            let vBox = findViewBox(element)
            if (vBox.left !== 0 || vBox.right !== 0 || vBox.top !== 0 || vBox.bottom !== 0) {
                setViewBox(vBox)
            }
        }
        // eslint-disable-next-line
    }, [document.getElementById('drawing'), drawingContext.current])
    useEffect(() => {
        if (viewBox != null) {
            // console.log(viewBox)
            // set svg width and height to zero
            element.setAttribute('width', 0)
            // get container dimensions            
            let container = document.getElementById('DrawingContainer').getBoundingClientRect()
            // set viewbox dimensions           
            element.setAttribute('width', container.width - 40)
            // element.setAttribute('height', (container.width*2/3))
            element.setAttribute(
                'viewBox',
                // (viewBox.left - 500) + ' ' + (viewBox.top - 500)  + ' ' + (viewBox.right - 6000) + ' ' + (viewBox.bottom - 6000)
                (viewBox.left) + ' ' + (viewBox.top) + ' ' + (viewBox.right) + ' ' + (viewBox.bottom)
            );
            setLoadingPart(false)
        }
        // eslint-disable-next-line
    }, [viewBox])

    const viewItem = drawingCode => {
        drawingContext.getDrawingItem(drawingCode)
    }

    const viewDrawing = drawingId => {
        console.log(drawingId)
        history.push(`/view/${drawingId}`)
    }

    // find all parts
    const findParts = el => {        
        let items = [];
        let groups = el.getElementsByTagName('g');
        for (let i = 0; i < groups.length; i++) {
            const elementId = groups[i].id;
            if (elementId !== "") {

                // find item in current element
                const cItem = drawingContext.current.items.find(i => i.drawing_code === elementId);
                if (cItem !== undefined) {
                    if (cItem.sub_id !== null) {
                        groups[i].classList.add('linked-drawing');
                    } else if (cItem.part_id !== null) {
                        groups[i].classList.add('linked-part');
                    } else {
                        groups[i].classList.add('linked');
                    }
                }            

                // store element id
                items.push(elementId);
                // add classes and events
                groups[i].classList.add('part');
                // mount change
                groups[i].onmouseover = () => {
                    groups[i].classList.add('active')
                }
                groups[i].onmouseout = () => {
                    groups[i].classList.remove('active')
                }
                groups[i].onclick = () => {
                    if (cItem !== undefined && cItem.sub_id !== null) {
                        viewDrawing(cItem.sub_id)
                    } else {
                        viewItem(elementId)
                    }                    
                }

                    
            }
        }
        return items
    }

    // find view box
    const findViewBox = el => {
        let groups = el.getElementsByTagName('g');
        let vBox = { left: 0, right: 0, top: 0, bottom: 0 }

        for (let i = 0; i < groups.length; i++) {
            if (groups[i].id !== "") {
                // find viewBox
                let elRect = groups[i].getBoundingClientRect()

                vBox.left = (vBox.left === 0 || vBox.left > elRect.left ? elRect.left : vBox.left)
                vBox.right = (vBox.right === 0 || vBox.right < elRect.right ? elRect.right : vBox.right)
                vBox.top = (vBox.top === 0 || vBox.top > elRect.top ? elRect.top : vBox.top)
                vBox.bottom = (vBox.bottom === 0 || vBox.bottom < elRect.bottom ? elRect.bottom : vBox.bottom)
            }
        }
        return vBox
    }

    const getImageContent = () => {
        if (drawingContext.current !== null) {
            return { __html: drawingContext.current.content };
        }
    }

    const removeSubId = (itemId) => {
        drawingContext.updateDrawingItem(itemId, {
            'sub_id': null
        })
        setShowItemList(false)
    }
    
    if (drawingContext.loading) {
        return <Spinner />;
    }

    return (
        <div id="DrawingContainer">
            {!loadingParts &&
                <React.Fragment>
                    <div className="top-bar">
                        <div className="breadcrumb">
                            <ul>
                                {drawingContext.current && <li>{drawingContext.current.name}</li>}
                                {drawingContext.item && <li>{drawingContext.item.name}</li>}
                            </ul>
                        </div>
                        <div className="navigation">
                            <button onClick={() => setShowItemList(true)}><FontAwesomeIcon icon={faList} size="2x" title="Seznam dílů z animace" /></button>
                            {drawingContext.current && <Link to={'/drawing/' + drawingContext.current.id}><FontAwesomeIcon icon={faEdit} size="2x" title="Upravit animaci" /></Link>}
                        </div>
                    </div>

                    <Modal isShowing={showItemList} hide={() => setShowItemList(false)}>
                        <DrawingItemList 
                            drawingItems={parts} 
                            storedItems={drawingContext.current?.items} 
                            removeSubId={removeSubId}
                        />
                    </Modal>
                </React.Fragment>
            }
            <MapInteractionCSS
              minScale={0.5}
              maxScale={5}
              showControls={true}
            >
                <svg
                    id="drawing"
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.0"
                    dangerouslySetInnerHTML={getImageContent()}
                />
            </MapInteractionCSS>            
        </div>
    )
}

export default DrawingDetail
