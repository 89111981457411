import React, { Component } from 'react'

import DrawingItem from '../drawing/DrawingItem'

export class Aside extends Component {

    render() {
        return (
            <aside>                
                <DrawingItem />
            </aside>
        )
    }
}

export default Aside
