import React, { useContext, useEffect, useState, Fragment } from 'react'

import DrawingContext from '../../context/drawing/drawingContext'
import Spinner from '../layout/Spinner'
import PartDetail from '../part/PartDetail'

const DrawingItem = () => {
    const drawingContext = useContext(DrawingContext)

    const { loading_item, item } = drawingContext;

    useEffect(() => {
        if (item !== null) {
            setName(item.name)
        }
    }, [item])


    const [name, setName] = useState('');
    const [nameChanged, setNameChanged] = useState(false);

    const changeName = e => {
        setName(e.target.value)
        setNameChanged(true)
    }

    /**
     * Store drawing item name
     */
    const saveName = () => {
        drawingContext.updateDrawingItem(item.id, {
            'name': name
        })
        setNameChanged(false)
    }

    /**
     * Store drawing item part id
     * @param {int} partId 
     */
    const savePartId = (partId) => {
        drawingContext.updateDrawingItem(item.id, {
            'part_id': partId
        })
    }

    /**
     * Remove drive item part id
     */
    const removePartId = () => {
        drawingContext.updateDrawingItem(item.id, {
            'part_id': null
        })
    }

    const saveDrawingId = (subId) => {
        drawingContext.updateDrawingItem(item.id, {
            'sub_id': subId
        })
    }
    const removeDrawingId = () => {
        drawingContext.updateDrawingItem(item.id, {
            'sub_id': null
        })
    }

    if (loading_item) {
        return <Spinner />
    }

    return (
        <div>
            {item &&
                <Fragment>
                    <div className="part-info">
                        <h3>Díl v animaci</h3>
                        <div className="group">
                            <div>
                                <h4>Identifikátor</h4>
                                <p>{item.drawing_code}</p>
                            </div>
                            <div>
                                <h4>Název dílu v animaci</h4>
                                <input type="text" placeholder="název dílu" name="name" value={name || ''} onChange={changeName} />
                                {nameChanged === true && <button onClick={saveName}>Uložit</button>}
                            </div>
                        </div>
                    </div>

                    <PartDetail
                        partId={item.part_id}
                        savePartId={savePartId}
                        removePartId={removePartId}
                        saveDrawingId={saveDrawingId}
                        removeDrawingId={removeDrawingId}
                    />

                </Fragment>
            }
        </div>
    )
}

export default DrawingItem
