import {
    DRAWING_GET,
    DRAWING_GET_ALL,
    DRAWING_ADD,
    DRAWING_UPDATE,
    DRAWING_DELETE,
    DRAWING_ITEM_GET,
    DRAWING_ITEM_UPDATE,
    LOADING_SET,
    LOADING_ITEM_SET,
    LOADING_LIST_SET,
    CLEAR_CURRENT
}
    from '../types'

export default (state, action) => {
    switch (action.type) {
        case DRAWING_GET_ALL:
            return {
                ...state,
                list: action.payload,
                loading_list: false
            };
        case DRAWING_GET:
            return {
                ...state,
                current: action.payload.data,
                reloadCurrent: false,
                loading: false
            };
        case DRAWING_ADD:
            return {
                ...state,
                current: action.payload,
                list: [...state.list, action.payload],
                loading: false
            };
        case DRAWING_UPDATE:
            return {
                ...state,
                current: action.payload,
                loading: false
            };
        case DRAWING_DELETE:
            return {
                ...state,
                current: null,
                loading: false
            };
        case DRAWING_ITEM_GET:
            return {
                ...state,
                item: action.payload,
                loading_item: false
            };
        case DRAWING_ITEM_UPDATE:
            return {
                ...state,
                loading_item: false,
                reloadCurrent: true
            };
        case CLEAR_CURRENT:
            return {
                ...state,
                current: null,
                loading: false
            };

        case LOADING_SET:
            return {
                ...state,
                loading: true
            };
        case LOADING_ITEM_SET:
            return {
                ...state,
                loading_item: true
            };
        case LOADING_LIST_SET:
            return {
                ...state,
                loading_list: true
            };
        default:
            return state;
    }
};