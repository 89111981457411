// drawing
export const DRAWING_GET = 'DRAWING_GET'
export const DRAWING_GET_ALL = 'DRAWING_GET_ALL'
export const DRAWING_ADD = 'DRAWING_ADD'
export const DRAWING_UPDATE = 'DRAWING_UPDATE'
export const DRAWING_DELETE = 'DRAWING_DELETE'

// drawing item
export const DRAWING_ITEM_GET = 'DRAWING_ITEM_GET'
export const DRAWING_ITEM_UPDATE = 'DRAWING_ITEM_UPDATE'
export const DRAWING_ITEM_UPDATED = 'DRAWING_ITEM_UPDATED'
export const DRAWING_ITEM_DELETE = 'DRAWING_ITEM_DELETE'

// part
export const PART_GET = 'PART_GET'
export const PART_FIND = 'PART_FIND'
export const PART_ADD = 'PART_ADD'
export const PART_UPDATE = 'PART_UPDATE'
export const PART_DELETE = 'PART_DELETE'
export const PART_ELEMENT_SET = 'PART_ELEMENT_SET'
export const PART_SET_FILTER = 'PART_SET_FILTER'

// part stock
export const PART_STOCK_GET = 'PART_STOCK_GET'
export const PART_STOCK_ADD = 'PART_STOCK_ADD'
export const PART_STOCK_COUNT = 'PART_STOCK_COUNT'

// common
export const LOADING_SET = 'LOADING_SET'
export const LOADING_ITEM_SET = 'LOADING_ITEM_SET'
export const LOADING_LIST_SET = 'LOADING_LIST_SET'

export const CLEAR_CURRENT = 'CLEAR_CURRENT'
export const ERROR = 'ERROR'

export const SET_ALERT = 'SET_ALERT'
export const REMOVE_ALERT = 'REMOVE_ALERT'
