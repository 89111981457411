import React from 'react'
import PropTypes from 'prop-types'

const DrawingItemList = ({ drawingItems, storedItems, removeSubId }) => {

  return (
    <div>
      <table className="part-table">
        <thead>
          <tr>
            <th>Název dílu v animaci</th>
            <th>Vazba</th>
          </tr>                
          </thead>
          <tbody>
            {drawingItems && drawingItems.map((item) => {

              const cItem = storedItems.find(i => i.drawing_code === item);

              return (
                <tr key={item}>
                  <td>{item}</td>
                  <td>{ cItem !== undefined && 
                    (cItem.sub_id !== null ? 
                      <span>Vazba na animaci <button onClick={() => removeSubId(cItem.id )}>odstranit</button></span> : 
                      (cItem.part_id !== null ? 'Vazba na díl' : '') 
                    )
                  }</td>
                </tr>                    
              )
            }            
          )}                
        </tbody>  
      </table>
    </div>
  )
}

DrawingItemList.propTypes = {
    drawingItems: PropTypes.array.isRequired,
    storedItems: PropTypes.array,
    removeSubId: PropTypes.func.isRequired,
}

export default DrawingItemList
