import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Modal from '../modal/Modal'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

import PartContext from '../../context/part/partContext'
import Spinner from '../layout/Spinner'
import PartEdit from './PartEdit'
import PartList from './PartList'
import PartInfo from './PartInfo'
import PartFilter from './PartFilter'
import PartStock from './PartStock'
import DrawingOptions from '../drawing/DrawingOptions'

const PartDetail = (props) => {
  const partContext = useContext(PartContext)

  const { loading, current, updateItem } = partContext
  const [edit, setEdit] = useState(false)
  const [showParts, setShowParts] = useState(false)
  const [showDrawings, setShowDrawings] = useState(false)

  useEffect(() => {
      if (typeof props.partId == 'undefined' || props.partId == null) {
          partContext.clearCurrent()
      } else {
          partContext.getPart(props.partId)
      }
      // eslint-disable-next-line
  }, [props.partId])
  useEffect(() => {
      // if part is created, update drawing item with part id and than set udpateItem to false
      if (updateItem) {
          props.savePartId(current.id)
          partContext.itemUpdated()
      }
      // eslint-disable-next-line
  }, [updateItem])


  /**
   * Called from modal window with part search
   * @param {int} id 
   */
  const selectPart = async (id) => {
      // load part information
      await partContext.getPart(id)
      // close window
      setShowParts(false)
      // save part to drawing
      props.savePartId(id)
  }

  /**
   * Remove part id from drawing item
   */
  const removePart = () => {
      props.removePartId()
  }

  /**
   * Called from modal window with drawing search
   * @param {int} id 
   */
  const setDrawing = async (id) => {      
    setShowDrawings(false); // close window      
    props.saveDrawingId(id); // save part to drawing
  }
  const removeDrawing = () => {
    props.removeDrawingId();
  }

  const saveForm = (form) => {
      if (current === null) {
          // create new part and set updateItem to true
          partContext.addPart(form)
      } else {
          partContext.updatePart(current.id, form)
          setEdit(false)
      }
  }


  if (loading) {
      return <Spinner />
  }

  return (
      <div>
        {!current &&
          <div className="part-missing">
            <p>Nemáte vybraný žádný díl příslušící k této položce.</p>
            <p>Máte tyto možnosti:</p>
            <ul className="link-list">
                <li><a onClick={() => setShowParts(true)}>Vyhledat existující díl</a></li> 
                <li><a onClick={() => setEdit(true)}>Přidat nový díl</a></li>
                <li><a onClick={() => setShowDrawings(true)}>Propojit na jinou animaci</a></li>
            </ul>
          </div>
        }

        {(current && !edit) &&
          <React.Fragment>
            <div className="top-bar">
                <div className="title">Informace o dílu</div>
                <div className="navigation">
                  <button onClick={() => setShowParts(true)}><FontAwesomeIcon icon={faSearch} size="1x" title="Vyhledat jiný díl" /></button>
                  <button onClick={() => setEdit(true)}><FontAwesomeIcon icon={faEdit} size="1x" title="Upravit díl" /></button>
                  <button onClick={removePart}><FontAwesomeIcon icon={faTrash} size="1x" title="Zrušit spojení" /></button>
                </div>
            </div>
            <PartInfo />
            <PartStock partId={current.id} />
          </React.Fragment>
        }

        {edit && <PartEdit saveForm={saveForm} />}

        <Modal isShowing={showParts} hide={() => setShowParts(false)}>
            <PartFilter />
            <PartList selectPart={selectPart} />
        </Modal>

        <Modal isShowing={showDrawings} hide={() => setShowDrawings(false)}>
            <DrawingOptions setDrawing={setDrawing} />
        </Modal>


      </div>
  )
}

PartDetail.propTypes = {
    partId: PropTypes.number,
    savePartId: PropTypes.func.isRequired,
    removePartId: PropTypes.func.isRequired,
    saveDrawingId: PropTypes.func.isRequired,
    removeDrawingId: PropTypes.func.isRequired,
}

export default PartDetail
