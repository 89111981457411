import React, { useEffect, useContext, useState } from 'react'
import PartContext from '../../context/part/partContext'

const PartFilter = () => {

  const context = useContext(PartContext)

  const [fulltext, setFulltext] = useState('');

  useEffect(() => {
     setFulltext(context.filter)
     // eslint-disable-next-line
  }, [])

  const onChange = e => {
    setFulltext(e.target.value);
  }  

  const onSubmit = e => {
    e.preventDefault();
    context.setFilter(fulltext)
  };

  
  return (
    <form onSubmit={onSubmit}  className="part-filter">
        <input type="text" placeholder="vyhledat" name="fulltext" value={fulltext} onChange={onChange} />
        <button className="button-default">Vyhledat</button>
  </form>
  )
}

export default PartFilter
