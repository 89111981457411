import React from 'react'
import { Link } from 'react-router-dom'

import DrawingList from '../drawing/DrawingList'

const Navigation = () => {

    return (
        <nav>
            <div className="buttonPanel">
                <Link to="/drawing" className={"buttonLink"}>přidat novou animaci</Link>
            </div>

            <DrawingList />
        </nav>
    )
}

export default Navigation
