import React from 'react';
import {Helmet} from "react-helmet";
import { BrowserRouter as Router } from "react-router-dom"

import DrawingState from './context/drawing/DrawingState';
import PartState from './context/part/PartState';
import PartStockState from './context/partStock/PartStockState';
import AlertState from './context/alert/AlertState';

import Header from './components/layout/Header'
import Navigation from './components/layout/Navigation'
import Main from './components/layout/Main'
import Aside from './components/layout/Aside'
import Footer from './components/layout/Footer'

import './scss/App.scss';

const App = () => {
  return (
    <AlertState>
      <DrawingState>      
        <PartState>
          <PartStockState>
            <Router>
              <Helmet>
                <title>ePit Katalog</title>
              </Helmet>
              <Header />              
              <div id="main">                
                  <Navigation />
                  <Main />
                  <Aside />
              </div>
              <Footer />
            </Router>
          </PartStockState>
        </PartState>
      </DrawingState>
    </AlertState>
  );
}

export default App;