import {
    PART_STOCK_GET,
    PART_STOCK_ADD,
    PART_STOCK_COUNT,
    LOADING_SET
} from '../types';

export default (state, action) => {
    switch (action.type) {

        case PART_STOCK_GET:
            return {
                ...state,
                partId: action.partId,
                history: action.payload,
                update: false,
                loading: false
            };
        case PART_STOCK_ADD:
            return {
                ...state,
                // history: [...state.history, action.payload],
                partId: action.payload.part_id,
                update: true,
                loading: false
            };
        case PART_STOCK_COUNT:
            return {
                ...state,
                current_amount: action.payload
            }
        case LOADING_SET:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
};