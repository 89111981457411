import React, { useEffect, useContext, useState } from 'react'
import { Redirect } from "react-router-dom"
import Modal from '../modal/Modal'

import DrawingContext from '../../context/drawing/drawingContext'
import Spinner from '../layout/Spinner'
import useModal from '../../hooks/useModal'

const DrawingForm = ({ match }) => {

    const context = useContext(DrawingContext)
    const { isShowing, toggle } = useModal()

    const initialState = {
        id: '',
        project_id: '',
        name: '',
        thumbnail_type: '',
        thumbnail: '',
        content_type: '',
        content: ''
    }

    const [drawing, setDrawing] = useState(initialState);
    const [loadingImage, setLoadingImage] = useState(false);

    const {current} = context

    useEffect(() => {
        if (match.params.id && match.params.id > 0) {
            context.getDrawing(match.params.id)            
        } else {
            context.clearCurrent()                     
        }
        // eslint-disable-next-line
    }, [match])
    useEffect(() => {
        if (current != null) {
            setDrawing({
                ...drawing,
                id: current.id,
                project_id: current.project_id,
                name: current.name
            })
        } else {
            setDrawing(initialState)            
            setDrawing({
                ...drawing,
                project_id: context.project_id
            })            
        }   
        // eslint-disable-next-line   
    }, [current])

    const onChange = e => {
        setDrawing({ ...drawing, [e.target.name]: e.target.value });
    }

    const onFileChange = async e => {
        let name = e.target.name;
        let file = e.target.files[0];

        if (file) {
            setLoadingImage(true)
            let fileData = await toBase64(file)
            fileData = fileData.split(";base64,")
            setDrawing({
                ...drawing,
                [name + '_type']: fileData[0].replace("data:", ""),
                [name]: fileData[1]
            });
            setLoadingImage(false)
        }
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const onSubmit = e => {
        e.preventDefault();

        if (context.current === null) {
            context.addDrawing(drawing)
        } else {
            context.updateDrawing(drawing.id, drawing)
        }
    };

    const onDelete = e => {
        // stop default actions
        e.preventDefault()
        context.removeDrawing(current.id)
        context.clearCurrent()
        toggle()
        return <Redirect to='/view' />
    }

    return (
        <React.Fragment>
        <form onSubmit={onSubmit} className="drawing-edit-form">
            <h2>{context.current ? "Upravit animaci" : "Přidat novou animaci" }</h2>
            <div className="row">
                <div className="column">

                    <div className="input-group">
                        <label>ID</label>
                        <input type="text" value={drawing.id || ''} readOnly />
                    </div>
                    
                    <div className="input-group">
                        <label>PROJECT_ID</label>
                        <input type="text" value={drawing.project_id || ''} readOnly />
                    </div>
                    <div className="input-group">
                        <label>Název dílu</label>
                        <input type="text" placeholder="název dílu" name="name" value={drawing.name || ''} onChange={onChange} />
                    </div>
                    <div className="input-group">
                        <label>Náhledový obrázek</label>
                        <input type="file" name="thumbnail" onChange={onFileChange} />
                    </div>
                    <div className="input-group">
                        <label>Animace</label>
                        <input type="file" name="content" onChange={onFileChange} />
                    </div>

                </div>
            </div>

            <div className="row">
                {loadingImage ? <Spinner /> : <button className="button-default">{context.current ? "Uložit" : "Přidat"}</button>}
                {context.current && <button className="button-delete" onClick={toggle}>Odstranit</button>}
            </div>
        </form>

        <Modal isShowing={isShowing} hide={toggle}>
            <h3>Opravdu chcete odstranit tuto animaci</h3>
            <form onSubmit={onDelete} >
                <div className="row">
                    <button className="button-default">Odstranit</button>
                    <button className="button-delete" onClick={toggle}>Zrušit</button>
                </div>
            </form>
        </Modal>

        </React.Fragment>
    )
}

export default DrawingForm
