import React, { useReducer, useEffect } from 'react'
import AxiosInstance from '../../utils/AxiosInstance'
import PartContext from './partContext';
import PartReducer from './partReducer';
import {
    PART_GET,
    PART_FIND,
    PART_ADD,
    PART_UPDATE,
    PART_DELETE,
    PART_SET_FILTER,
    DRAWING_ITEM_UPDATED,
    LOADING_SET,
    LOADING_LIST_SET,
    CLEAR_CURRENT,
    ERROR
} from '../types';

const PartState = props => {
    const initialState = {
        current: null,
        list: [],
        filter: '',
        updateItem: false,
        loading: false,
        loading_list: false,
        error: null
    };

    const [state, dispatch] = useReducer(PartReducer, initialState);

    useEffect(() => {
        // update part list after filter update
        findPart()
        // eslint-disable-next-line
    }, [state.filter])

    // Set Loading
    const setLoading = () => dispatch({ type: LOADING_SET });
    const setLoadingList = () => dispatch({ type: LOADING_LIST_SET });

    const clearCurrent = () => {
        setLoading();

        dispatch({
            type: CLEAR_CURRENT
        })
    }

    // set filter
    const setFilter = (fulltext) => {
        dispatch({
            type: PART_SET_FILTER,
            payload: fulltext
        });       
    }

    // Find Part
    const findPart = async () => {
        setLoadingList();
        try {
            const res = await AxiosInstance.get('/part?fulltext=' + state.filter);
            dispatch({
                type: PART_FIND,
                payload: res.data
            });
        } catch (err) {
            dispatch({
                type: ERROR,
                payload: (err.response.data && err.response.data)
            });
        }
    }
   
    // Get Part
    const getPart = async partId => {
        setLoading();
        try {
            const res = await AxiosInstance.get('/part/' + partId);
            dispatch({
                type: PART_GET,
                payload: res.data
            });
        } catch (err) {

            dispatch({
                type: ERROR,
                payload: (err.response.data.message && err.response.data.message)
            });
        }       
    };

    // Add Part
    const addPart = async (part) => {
        setLoading();

        try {
            const res = await AxiosInstance.post('/part', part);
            dispatch({
                type: PART_ADD,
                payload: res.data
            });

        } catch (err) {

            dispatch({
                type: ERROR,
                payload: (err.response.data.message && err.response.data.message)
            });
        }
    };
    
    // Update Part
    const updatePart = async (id, part) => {
        setLoading();
        try {
            const res = await AxiosInstance.put('/part/' + id, part);
            dispatch({
                type: PART_UPDATE,
                payload: res.data
            });
        } catch (err) {
            dispatch({
                type: ERROR,
                payload: err.response.data.message
            });
        }
    };

    // Delete Part
    const deletePart = async id => {
        setLoading();

        try {
            await AxiosInstance.delete(`/part/${id}`);

            dispatch({
                type: PART_DELETE,
                payload: id
            });
        } catch (err) {
            dispatch({
                type: ERROR,
                payload: err.response.data.message
            });
        }

        clearCurrent();
    };

    const itemUpdated = () => {
        dispatch({
            type: DRAWING_ITEM_UPDATED
        })
    }


    return <PartContext.Provider
        value={{
            loading: state.loading,
            loading_list: state.loading_list,
            error: state.error,
            current: state.current,
            list: state.list,
            filter: state.filter,
            updateItem: state.updateItem,
            setFilter,
            clearCurrent,
            findPart,
            getPart,
            addPart,
            updatePart,
            deletePart,
            itemUpdated
        }}
    >
        {props.children}
    </PartContext.Provider>

}

export default PartState;