import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'

import DrawingContext from '../../context/drawing/drawingContext'
import Spinner from '../layout/Spinner'

const DrawingList = () => {
    const context = useContext(DrawingContext)

    useEffect(() => {
        context.getAllDrawings(1)
        // eslint-disable-next-line
    }, [])

    const items = context.list.map((i) => (
        <li key={i.id}>
            <Link to={`/view/${i.id}`}>
                <img width={200} height={150} src={process.env.REACT_APP_API_URL + 'images/' + i.thumbnail} alt={i.thumbnail} />
                {i.name}
            </Link>            
        </li>
    ))


    if (context.loading_list) {
        return <Spinner />;
    } 

    return (
        <ul className="drawing-list">
            { items }
        </ul>

    )
}

export default DrawingList
