import React, { useContext, useEffect} from 'react'
import PropTypes from 'prop-types'

import PartContext from '../../context/part/partContext'
import Spinner from '../layout/Spinner'

const PartList = props => {
    const partContext = useContext(PartContext)

    useEffect(() => {
        partContext.findPart()
        // eslint-disable-next-line
    }, [])

    if (partContext.loading_list) {
        return <Spinner />
    }

    return (
        <table className="part-table">
            <thead>
                <tr>
                    <th>Interní kód</th>
                    <th>Název dílu</th>
                    <th>Kód dodavatele</th>
                    <th>Označení dodavatele</th>
                </tr>                
            </thead>
            <tbody>
                {partContext.list && partContext.list.map((item) => 
                <tr key={item.id} onClick={() => props.selectPart(item.id)}>
                    <td>{item.code}</td>
                    <td>{item.name}</td>
                    <td>{item.supplier}</td>
                    <td>{item.supplier_code}</td>
                </tr>                    
                )}                
            </tbody>
        
        </table>
    )
}

PartList.propTypes = {
    selectPart: PropTypes.func.isRequired,
}

export default PartList
