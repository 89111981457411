import React, { useContext, useEffect, useReducer } from 'react'
import AxiosInstance from '../../utils/AxiosInstance'
import DrawingContext from './drawingContext';
import DrawingReducer from './drawingReducer';
import {
    DRAWING_GET,
    DRAWING_GET_ALL,
    DRAWING_ADD,
    DRAWING_UPDATE,
    DRAWING_DELETE,

    DRAWING_ITEM_GET,
    DRAWING_ITEM_UPDATE,

    LOADING_SET,
    LOADING_ITEM_SET,
    LOADING_LIST_SET,
    CLEAR_CURRENT,
    ERROR
} from '../types';
import AlertContext from '../../context/alert/alertContext';

const DrawingState = props => {
    const alertContext = useContext(AlertContext)

    const initialState = {
        projectId: 1,
        list: [],
        current: null,
        item: null,
        reloadCurrent: false,
        loading: false,
        loading_item: false,
        loading_list: false
    };
    const [state, dispatch] = useReducer(DrawingReducer, initialState);

    // Set Loading
    const setLoading = () => dispatch({ type: LOADING_SET });
    const setLoadingItem = () => dispatch({ type: LOADING_ITEM_SET });
    const setLoadingList = () => dispatch({ type: LOADING_LIST_SET });

    useEffect(() => {
        if (state.reloadCurrent) {
            getDrawing(state.current.id)
        }
    }, [state.reloadCurrent])

    const clearCurrent = () => {
        setLoading();
        dispatch({
            type: CLEAR_CURRENT
        })
    }

    // Get All Drawings
    const getAllDrawings = async projectId => {
        setLoadingList();
        const res = await AxiosInstance.get('/drawing?project_id=' + projectId);
        dispatch({
            type: DRAWING_GET_ALL,
            payload: res.data
        });
    };

    // Get Drawing
    const getDrawing = async drawingId => {
        setLoading();
        const res = await AxiosInstance.get('/drawing/' + drawingId);
        dispatch({
            type: DRAWING_GET,
            payload: res.data
        });
    };    

    // Add Drawing
    const addDrawing = async drawing => {
        setLoading();
        try {
            const res = await AxiosInstance.post('/drawing', drawing);
            dispatch({
                type: DRAWING_ADD,
                payload: res.data
            });
        } catch (err) {

            dispatch({
                type: ERROR,
                payload: (err.response && err.response)
            });
        }
        // reload drawing list
        getAllDrawings(state.projectId)
    };

    // Update Drawing
    const updateDrawing = async (id, data) => {
        setLoading();
        try {
            const res = await AxiosInstance.put('/drawing/' + id, data);
            dispatch({
                type: DRAWING_UPDATE,
                payload: res.data
            });
            alertContext.setAlert('Animace byla aktualizována.', 'success')

        } catch (err) {
            dispatch({
                type: ERROR,
                payload: (err.response && err.response)
            });
            alertContext.setAlert('Animaci se nepodařilo aktualizovat.', 'danger')
        }

        // reload drawing list
        getAllDrawings(state.projectId)

        
    };

    const removeDrawing = async (id) => {
        setLoading();
        try {
            const res = await AxiosInstance.delete('/drawing/' + id);
            dispatch({
                type: DRAWING_DELETE,
                payload: res.data
            });
        } catch (err) {
            dispatch({
                type: ERROR,
                payload: (err.response && err.response)
            });
        }
        // reload drawing list
        getAllDrawings(state.projectId)
    }

    // Get Drawing Item
    const getDrawingItem = async ( drawingCode )  => {     
        setLoadingItem()

        // try to find part in database
        const res = await AxiosInstance.get('/drawing-item?drawing_id=' + state.current.id + '&element=' + drawingCode);
        dispatch({
            type: DRAWING_ITEM_GET,
            payload: res.data
        })
    }

    // Update DrawingItem
    const updateDrawingItem = async (id, data) => {
        setLoadingItem();
        try {
            const res = await AxiosInstance.put('/drawing-item/' + id, data);
            dispatch({
                type: DRAWING_ITEM_UPDATE,
                payload: res.data
            });
            alertContext.setAlert('Vybraný díl animace byl upraven.', 'success')

        } catch (err) {
            dispatch({
                type: ERROR,
                payload: (err.response && err.response)
            });
            alertContext.setAlert('Vybraný díl se nepodařilo upravit.', 'danger')
        }
    };    

    return <DrawingContext.Provider
        value={{
            current: state.current,
            item: state.item,
            loading: state.loading,
            loading_item: state.loading_item,
            loading_list: state.loading_list,
            project_id: state.projectId,
            list: state.list,
            clearCurrent,
            getAllDrawings,
            getDrawing,
            addDrawing,
            updateDrawing,
            removeDrawing,
            getDrawingItem,
            updateDrawingItem
        }}
    >
        {props.children}
    </DrawingContext.Provider>

}

export default DrawingState;