import React, { useContext } from 'react'

import PartContext from '../../context/part/partContext'

const PartInfo = () => {
    const context = useContext(PartContext)

    const { current } = context

    return (
        <div className="part-info">

            <div className="group">
                <div>
                    <h4>Název dílu</h4>
                    <p>{current.name}</p>
                </div>
                <div>
                    <h4>Interní kód</h4>
                    <p>{current.code}</p>
                </div>
                <div>
                    <h4>Kód dodavatele</h4>
                    <p>{current.supplier}</p>
                </div>
                <div>
                    <h4>Označení dodavatele</h4>
                    <p>{current.supplier_code}</p>
                </div>
                <div>
                    <h4>Poznámka</h4>
                    <p>{current.note}</p>
                </div>
            </div>
        </div>
    )
}

export default PartInfo
