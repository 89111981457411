import React, { useEffect, useContext, useState } from 'react'
import PropTypes from 'prop-types'
// import { Link } from 'react-router-dom'
// import { CSSTransition, TransitionGroup } from 'react-transition-group'
import PartContext from '../../context/part/partContext'


const PartEdit = (prop) => {

  const context = useContext(PartContext)

  const initialState = {
    id: null,
    name: '',
    code: '',
    supplier: '',
    supplier_code: '',
    note: ''
  }

  const [form, setForm] = useState(initialState);

  useEffect(() => {
    if (context.current !== null) {
      setForm({ ...form, ...context.current})
    }
    // eslint-disable-next-line
  }, [])


  const onChange = e => {
    setForm({ ...form, [e.target.name]: e.target.value });
  }  

  const onSubmit = e => {
    e.preventDefault();

    prop.saveForm(form)
  };

  
  return (
    <form onSubmit={onSubmit}>
      <div className="part-info">
        <h3>{context.current ? "Upravit díl" : "Přidat nový díl"}</h3>
        <div className="group">
            <div>
                <h4>Název dílu</h4>
                <input type="text" placeholder="název" name="name" value={form.name || ''} onChange={onChange} />
            </div>
            <div>
                <h4>Interní kód</h4>
                <input type="text" placeholder="interní kód" name="code" value={form.code || ''} onChange={onChange} />
            </div>
            <div>
                <h4>Kód dodavatele</h4>
                <input type="text" placeholder="dodavatel" name="supplier" value={form.supplier || ''} onChange={onChange} />
            </div>
            <div>
                <h4>Označení dodavatele</h4>
                <input type="text" placeholder="označení" name="supplier_code" value={form.supplier_code || ''} onChange={onChange} />
            </div>
            <div>
                <h4>Poznámka</h4>
                <input type="text" placeholder="poznámka" name="note" value={form.note || ''} onChange={onChange} />
            </div>
        </div>
      </div>
      <button className="button-default">Uložit</button>
  </form>
  )
}

PartEdit.propTypes = {
  saveForm: PropTypes.func.isRequired
}

export default PartEdit
