import {
    PART_GET,
    PART_FIND,
    PART_ADD,
    PART_UPDATE,
    PART_DELETE,
    PART_SET_FILTER,
    DRAWING_ITEM_UPDATED,
    LOADING_SET,
    LOADING_LIST_SET,
    CLEAR_CURRENT,
    ERROR
} from '../types';

export default (state, action) => {
    switch (action.type) {

        case PART_SET_FILTER:
            return {
                ...state,
                filter: action.payload,
            };      
        case PART_FIND:
            return {
                ...state,
                list: action.payload,
                loading_list: false
            };      
        case PART_GET:
            return {
                ...state,
                current: action.payload,
                loading: false
            };
        case PART_ADD:
            return {
                ...state,
                current: action.payload,
                updateItem: true,
                loading: false
            };
        case PART_UPDATE:
            return {
                ...state,
                current: action.payload,
                loading: false
            };
        case PART_DELETE:
            return {
                ...state,
                current: null,
                loading: false
            };      
        case DRAWING_ITEM_UPDATED:
            return {
                ...state,
                updateItem: false
            }
        case CLEAR_CURRENT:
            return {
                ...state,
                current: null,
                loading: false
            };

        case ERROR:
            return {
                ...state,
                error: action.payload
            };


        case LOADING_SET:
            return {
                ...state,
                loading: true
            };
        case LOADING_LIST_SET:
            return {
                ...state,
                loading_list: true
            };
        default:
            return state;
    }
};